import React from "react"
import Layout from "react"
import Header from "../components/header/Header"
import HeaderMinimal from "../components/header/HeaderMinimal"

import MainMenu from "../components/header/Menu"
import HomeBody from "../components/home/HomeBody"
import Footer from "../components/footer/Footer"

import "../styles/global.css"
import "../styles/menu.css"

function MyApp () {

	return (
			<div className="site body">
				<Header />
				<MainMenu home="true"/>
				<main className="site-content">

					<form>
					<input type="file"/>
					</form>

				</main>
				<Footer />
			</div>
	)
}

export default MyApp 