import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../../styles/header.css"


function Header(props){
const data = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { relativePath: { regex: "images/header/parts/" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
const queried_images = data.allFile.edges
let images = {}
for (const property in queried_images) {
  const image_name = queried_images[property]['node']['name']
  const image = queried_images[property]['node']['childImageSharp']['fluid']
  images[image_name] = image  
}

console.log(images)

return (
    <Link to="/">

		<header className="header" alt="">

				<Img className="header-cross" 
					fluid={images['cross']}
      				alt=""/>
        <div className='header-location'>
        <h2>Eagle River, Alaska</h2>
        <h2>Antiochian Archdiocese</h2>
        </div>
        
        <Img className="header-mountains" 
          fluid={images['mountains']}
              alt=""/>

        <h1 className="name">Saint John Orthodox Cathedral</h1>
			</header>
			</Link>
	)
}

export default Header

